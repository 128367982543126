<template>
    <div class="modal">
        <div class="modal-close" @click="$emit('close')"></div>
        <div class="modal-container">
               <YouTube 
                    :src="video" 
                    @ready="onReady"
                    ref="youtube" 
                    :height="videoHeight"
                    :width="videoWidth"
                />
        </div>
    </div>
    
</template>

<script>
import YouTube from 'vue3-youtube';

export default {
    name : "ModalVideo",
    components: { YouTube },
    props : ["video"],
    methods: {
        onReady() {
            this.$refs.youtube.playVideo()
        },
    },
    data() {
        return {
            videoWidth: 1280,
            videoHeight : 720
        }
    },
    mounted() {
        if(window.innerWidth < 1600) {
            this.videoWidth = 640;
            this.videoHeight = 360;
        }
        else if(window.innerWidth < 768) {
            this.videoWidth = 320;
            this.videoHeight = 180;
        }else {
             this.videoWidth = 1280;
            this.videoHeight = 720;
        }
    }
}
</script>

<style scoped lang="scss">

</style>